// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/Robot.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notification_notification__rQccJ {
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: #00410b;
  right: 30px;
  border-radius: 1rem;
  border: 2px solid #2aff00;
  padding-right: 6rem !important;
  padding: 1rem;
  min-height: 110px;
  font-size: 1.2rem;
  min-width: 300px;
  color: #2aff00;
  display: flex;
  align-items: center;
  margin-top: 80px;
}

.Notification_danger__1rtbb {
  background-color: #4a0000;
  border: 2px solid #ff0000;
  color: #ff0000;
}

.Notification_notification__rQccJ::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: right 0px top 0px;
  filter: grayscale(100%) opacity(0.9);
  z-index: 99999;
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/components/Notification/Notification.module.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,MAAA;EACA,aAAA;EACA,yBAAA;EACA,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,8BAAA;EACA,aAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,yBAAA;EACA,yBAAA;EACA,cAAA;AAFF;;AAKA;EACE,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,oBAAA;EACA,4BAAA;EACA,sCAAA;EACA,oCAAA;EACA,cAAA;EACA,WAAA;AAFF","sourcesContent":["@import \"../../styles/_mixins.scss\";\n@import \"../../styles/_variables.scss\";\n\n.notification {\n  position: fixed;\n  top: 0;\n  z-index: 9999;\n  background-color: #00410b;\n  right: 30px;\n  border-radius: 1rem;\n  border: 2px solid #2aff00;\n  padding-right: 6rem !important;\n  padding: 1rem;\n  min-height: 110px;\n  font-size: 1.2rem;\n  min-width: 300px;\n  color: #2aff00;\n  display: flex;\n  align-items: center;\n  margin-top: 80px;\n}\n\n.danger {\n  background-color: #4a0000;\n  border: 2px solid #ff0000;\n  color: #ff0000;\n}\n\n.notification::before {\n  content: \"\";\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-image: url(\"../../assets/images/Robot.svg\");\n  background-size: 40%;\n  background-repeat: no-repeat;\n  background-position: right 0px top 0px;\n  filter: grayscale(100%) opacity(0.9);\n  z-index: 99999;\n  z-index: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": `Notification_notification__rQccJ`,
	"danger": `Notification_danger__1rtbb`
};
export default ___CSS_LOADER_EXPORT___;
