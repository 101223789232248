import React from "react";
import styles from "./Button.module.scss";

const Button = ({
  title,
  size,
  style,
  className,
  onClick,
  isGradient,
  isDisabled,
  type
}) => {
  const sizeClass =
    size === "large"
      ? styles.btn_large
      : size === "small"
      ? styles.btn_small
      : "";

  const gradientClass = isGradient ? styles.btn__grad : "";

  return (
    <button
      style={
        (isDisabled
          ? { cursor: "not-allowed", opacity: "0.5" }
          : { cursor: "pointer" },
        { ...style })
      }
      disabled={isDisabled}
      className={`${className} ${styles.button} ${sizeClass} ${gradientClass}`}
      onClick={onClick}
      type={type}
    >
      {title}
    </button>
  );
};

export default Button;

Button.defaultProps = {
  size: "",
  style: {},
  className: "",
  onClick: () => {},
  isGradient: false,
  isDisabled: false,
  type: "button"
};
