import React, { useEffect, useRef, useState } from "react";
import styles from "./Validation.module.scss";
import Button from "../../components/Button/Button";
import Select from "../../components/Select/Select";
import { getIsValidate, getSocials } from "../../services/Validation";
import Modal from "./components/Modal";

const Validation = () => {
  const errorMessage = useRef(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isValidSocial, setIsValidSocial] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prefix, setPrefix] = useState("");

  const fetchSocials = async () => {
    try {
      const response = await getSocials();

      setSelectOptions(response.map((item) => item.medium));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSocials();
  }, []);

  useEffect(() => {
    if (selectedValue.length > 0 && inputValue.length > 0) {
      errorMessage.current.innerText = "";
    }

    if (selectedValue === "Twitter(X)") {
      setPrefix("www.x.com/");
    } else if (selectedValue === "Telegram") {
      setPrefix("@");
    } else if (selectedValue === "Linkedin") {
      setPrefix("www.linkedin.com/in/");
    } else {
      setPrefix("");
    }
  }, [selectedValue, inputValue]);

  const handleChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.startsWith(prefix)) {
      setInputValue(inputValue.slice(prefix.length));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedValue.length <= 0 || inputValue.length <= 0) {
      errorMessage.current.innerText =
        "Please make sure you fill in all fields";
      return null;
    }

    errorMessage.current.innerText = "";
    try {
      let response = await getIsValidate({
        medium: selectedValue,
        username: inputValue,
      });
      setIsValidSocial(response);
      setIsModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main className={styles.container}>
      <div>
        <h1>Official Verification Center</h1>

        <p>
          To protect yourself from scammers pretending to be HyperGPT, use the
          Official Verification Center to confirm whether the information you've
          received is genuinely from HyperGPT.
        </p>
        <p>
          Simply input the information you want to verify into the search field
          below and click the Check button.
        </p>
        <p>
          You can utilize this service to verify: HyperGPT official Telegram
          IDs, X (Twitter) accounts and emails.
        </p>
      </div>

      <form className={styles.validation__form}>
        <div className={styles.validation__form_inputs}>
          <Select
            value={selectedValue}
            onChange={setSelectedValue}
            options={selectOptions}
            placeholder="Select"
          />

          <input
            type="text"
            placeholder="Enter the information to be verified"
            value={prefix + inputValue}
            onChange={(e) => handleChange(e)}
          />
          <p className={styles.error_message} ref={errorMessage} />
        </div>

        <Button
          title="Check Now"
          isGradient
          onClick={(e) => handleSubmit(e)}
          type="submit"
        />
      </form>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={styles.verification_modal}>
          <h3>
            {isValidSocial
              ? "This address has been verified by HyperGPT"
              : "This address has not been verified by HyperGPT"}
          </h3>
          <p>
            {isValidSocial ? (
              <>
                The address <b>“{inputValue}”</b> you entered has been verified
                by our system and is an official HyperGPT account or source. Do
                not trust any information that does not come from an official
                HyperGPT account or source.
              </>
            ) : (
              <>
                The address <b>“{inputValue}”</b> you entered could not be
                verified by our system. It is not an official HyperGPT account
                or source. Do not trust any information that does not come from
                an official HyperGPT account or source.
              </>
            )}
          </p>
          <Button
            title="Close"
            isGradient
            onClick={() => setIsModalOpen(false)}
            className={styles.modal_button}
          />
        </div>
      </Modal>
    </main>
  );
};

export default Validation;
