import Slider from "react-slick";
import styles from "./Carousel.module.scss";
import { FaLinkedin, FaEnvelope, FaTelegram } from "react-icons/fa"; // React icons paketinden ikonlar

function Carousel({ teamsInfo }) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.wrapper}>
      <Slider {...settings}>
        {teamsInfo
          .filter((member) => member.is_active)
          .map((member, index) => {
            // Telegram kullanıcı adından "@" işaretini kaldır
            const telegramUsername = member.telegram
              ? member.telegram.replace("@", "")
              : "";
            return (
              <div
                key={index}
                className={styles.item}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                  margin: "10px",
                }}
              >
                <img
                  src={
                    member.photo
                      ? member.photo
                      : "https://via.placeholder.com/150"
                  }
                  alt={member.fullname}
                />
                <div className={styles.textOverlay}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <strong>{member.fullname}</strong>
                    <span style={{ fontStyle: "italic" }}>
                      {member.position}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    {member.email && (
                      <a
                        style={{
                          color: "white",
                        }}
                        href={`mailto:${member.email}`}
                      >
                        <FaEnvelope />
                      </a>
                    )}
                    {member.linkedin && (
                      <a
                        style={{
                          color: "white",
                        }}
                        href={member.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedin />
                      </a>
                    )}
                    {telegramUsername && (
                      <a
                        style={{
                          color: "white",
                        }}
                        href={`https://t.me/${telegramUsername}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTelegram />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
}

export default Carousel;
