import React, { useEffect, useState } from "react";
import styles from "./About.module.scss";
import { aboutContent } from "../../utils/constants";
import Button from "../../components/Button/Button";
import Carousel from "../../components/Carousel/Carousel";

import {
  Latif,
  Bulent,
  Alex,
  Altug,
  Fırat,
  Hakan,
  Ilker,
  Merve,
  Soner,
  Sukran,
  Turan,
} from "../../assets";

const About = () => {
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    fetch("https://api.hypergpt.ai/team")
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.filter((item) => item.is_active);

        const sortedData = filteredData.sort((a, b) => a.order - b.order);

        setTeamData(sortedData);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <div className={styles.about}>
      <div className={styles.about_top}>
        <h2 className={`${styles.about_top__title} ${styles.title}`}>
          {aboutContent.firstTitle}
        </h2>
        <p className={`${styles.about_top__desc} ${styles.desc}`}>
          {aboutContent.firstDesc}
        </p>
      </div>

      <div className={styles.about_bottom}>
        {" "}
        <h2 className={`${styles.about_bottom__title} ${styles.title}`}>
          {aboutContent.secondTitle}
        </h2>
        <p className={`${styles.about_bottom__desc} ${styles.desc}`}>
          {aboutContent.secondDesc}
        </p>
        <Carousel
          items={[
            Latif,
            Bulent,
            Alex,
            Altug,
            Fırat,
            Hakan,
            Ilker,
            Merve,
            Soner,
            Sukran,
            Turan,
          ]}
          active={0}
          teamsInfo={teamData}
        />
        <p className={`${styles.about_bottom__desc___other} ${styles.desc}`}>
          {aboutContent.thirdTitle}
        </p>
      </div>

      <div className={styles.listBanner}>
        <div className={styles.listBanner__left}>
          <h2>{aboutContent.fourthTitle}</h2>
        </div>
        <div className={styles.listBanner__right}>
          <Button
            style={{
              backgroundColor: "#aa80f9",
            }}
            className={styles.listBanner__right__button__second}
            title={"Apply Now"}
          />
        </div>
      </div>
      <div className={styles.about_bottom}>
        {" "}
        <h2 className={`${styles.about_bottom__title} ${styles.title}`}>
          {aboutContent.fifthTitle}
        </h2>
        <p
          style={{
            width: "81%",
          }}
          className={`${styles.about_bottom__desc} ${styles.desc}`}
        >
          {aboutContent.fifthDesc}
        </p>
        <p className={`${styles.about_bottom__desc___other} ${styles.desc}`}>
          {aboutContent.sixthTitle}
        </p>
      </div>
    </div>
  );
};

export default About;
