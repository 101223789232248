import { instance as api } from "./Api";

export const getPartners = async () => {
  try {
    const response = await api.get(`/sponsors/`);
    return response.data;
  } catch (error) {
    console.error("An error occurred while fetching data: ", error);
    throw error;
  }
};