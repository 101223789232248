import React from "react";
import styles from "./Ecosystem.module.scss";
import { ecoTitle, ecoDesc, ecoCardItems } from "../../../utils/constants";
import DaoPeople from "../../../assets/images/DaoPeople.svg";
import { RightArrow } from "../../../assets/index";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
import Button from "../../../components/Button/Button";
import axios from "axios";
import { useContext } from "react";
import { NotificationContext } from "../../../context/NotificationContext";

const Ecosystem = ({ scrollToHowto }) => {
  const location = useLocation();
  const [newslatterMail, setNewslatterMail] = React.useState("");
  const { dispatch } = useContext(NotificationContext);

  const handleDaoPageClick = () => {
    if (location.pathname !== "/dao") {
      window.open("/dao", "_blank");
    }
  };

  const instance = axios.create({
    baseURL: "https://api.hypergpt.ai",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST",
      "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
      "X-Frame-Options": "DENY",
      "Content-Security-Policy": "default-src 'self'; script-src 'self'; style-src 'self'; frame-src 'self';"
    },
  });

  const handleSubscribe = async () => {
    try {
      const response = await instance.post(
        `/subscribeEmail?email=${newslatterMail}`
      );
      dispatch({
        type: "SET_NOTIFICATION",
        payload: {
          message: "You have successfully subscribed to our newsletter.",
          type: "success",
        },
      });
      setNewslatterMail("");
      console.log("responsess", response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.ecosystem}>
      <div className={styles.ecosystem__top}>
        <div className={styles.ecosystem__top__left}>
          <h2 className={styles.ecosystem__top__left__title}>{ecoTitle}</h2>
          <div className={styles.ecosystem__top__left__desc}>
            <p>{ecoDesc.first}</p>
            <p>{ecoDesc.second}</p>
          </div>
        </div>
        <div className={styles.ecosystem__top__right}>
          <button onClick={handleDaoPageClick}>+</button>
          <img src={DaoPeople} alt="" />
        </div>
      </div>
      <div className={styles.ecosystem__bottom}>
        {ecoCardItems.map((item, index) => {
          return (
            <div
              className={styles.ecosystem__bottom__card__container}
              key={index}
            >
              <a className={styles.ecosystem__bottom__card}>
                <span>{item.id}</span>
                <h3>{item.title}</h3>
                <p>{item.desc}</p>
              </a>
              {item.id !== "01" ? (
                <a
                  className={styles.ecosystem__bottom__card__button}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{item.buttonText}</span>
                  <img src={RightArrow} alt="" />
                </a>
              ) : (
                <a
                  onClick={scrollToHowto}
                  className={styles.ecosystem__bottom__card__button}
                >
                  <span>{item.buttonText}</span>
                  <img src={RightArrow} alt="" />
                </a>
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.newslatter_wrapper}>
        <div className={styles.newslatter_container}>
          <span>Join our newsletter today for free</span>
          <input
            value={newslatterMail}
            onChange={(e) => setNewslatterMail(e.target.value)}
            className={styles.newslatter_input}
            placeholder="Enter your email address"
          />
          <Button
            // onClick={() => {
            //   navigate("/dao");
            // }}
            onClick={handleSubscribe}
            style={{
              height: "50px",
            }}
            title="Subscribe Now"
            size="large"
            isGradient={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Ecosystem;
