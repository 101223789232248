import React, { useEffect, useState } from "react";

import Marquee from "react-fast-marquee";
import styles from "./Marquee.module.scss";

const Index = () => {
  const [partnersLogo, setPartnersLogo] = useState([]);

  useEffect(() => {
    fetch("https://api.hypergpt.ai/slider_sponsors/", {
      headers: {
        "X-Frame-Options": "DENY",
        "Content-Security-Policy": "default-src 'self'; script-src 'self'; style-src 'self'; frame-src 'self';"
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Fetched data:', data);
        const filteredData = data?.filter((item) => item?.is_slider);
        const sortedData = filteredData.sort((a, b) => a.order - b.order);
        setPartnersLogo(sortedData);
      })
      .catch((error) => console.error("Error:", error));
  }, []);
  console.log(partnersLogo);
  useEffect(() => {
    console.log('partnersLogo:', partnersLogo);
  }, [partnersLogo]);



  return (
    <Marquee>
      {partnersLogo.map((key, value) => (
        <img
          className={styles.Marquee__img}
          key={key}
          src={key.image_url}
          alt={key}
        />
      ))}
    </Marquee>
  );
};

export default Index;
