import { useEffect, useState } from "react";
import styles from "./Partners.module.scss";
import { getPartners } from "../../services/Partners";

export default function Partners() {
  const [partners, setPartners] = useState([]);

  const fetchPartners = async () => {
    try {
      const response = await getPartners();
      const sortedPartners = response.sort((a, b) => a.order - b.order);

      setPartners(sortedPartners);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPartners();
  }, [])
  
  return (
    <main className={styles.container}>
      <h1>Partners</h1>
      <p className={styles.hashtag}>#StrongerTogether</p>

      <div className={styles.partners__grid}>
        {partners.map((partner) => (
            <div className={styles.partners__grid_card} key={partner.id}>
              <img src={partner.image_url} alt={partner.name} className={styles.partners__grid_image} />
            </div>
          ))}
      </div>
    </main>
  );
}
