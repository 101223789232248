export const menuItems = [
  {
    name: "Solutions",
    path: "/",
    isSubmenu: true,
    submenuItems: [
      {
        name: "HyperX Pad",
        path: "https://hyperxpad.com/",
      },
      {
        name: "HyperStore",
        path: "/hyperstore",
      },
      {
        name: "HyperSDK",
        path: "/hypersdk",
      },
      {
        name: "HyperNFT",
        path: "/hypernft",
      },
      {
        name: "HyperRaffle",
        path: "https://store.hypergpt.ai/HyperRaffle",
      },
    ],
  },
  {
    name: "$HGPT",
    path: "/",
    isSubmenu: true,
    submenuItems: [
      {
        name: "Buy on Mexc",
        path: "https://www.mexc.com/exchange/HGPT_USDT",
      },
      {
        name: "Buy on Bitpanda",
        path: "https://www.bitpanda.com/en/prices/hypergpt-hgpt",
      },
      {
        name: "Buy on Gateio",
        path: "https://www.gate.io/trade/HGPT_USDT",
      },
      {
        name: "Buy on BingX",
        path: "https://bingx.com/spot/HGPTUSDT",
      },
      {
        name: "Buy on Bitmart",
        path: "https://www.bitmart.com/trade/en-US?symbol=HGPT_USDT ",
      },
      {
        name: "Buy on Pancakeswap",
        path: "https://pancakeswap.finance/swap?outputCurrency=0x529C79f6918665EBE250F32eeEAA1d410a0798C6&inputCurrency=0x55d398326f99059fF775485246999027B3197955",
      },
      {
        name: "Stake $HGPT",
        path: "https://staking.hypergpt.ai/",
      },
      {
        name: "Claim Now",
        path: "https://iron-vest-hypergpt.netlify.app/user/dashboard/0x529C79f6918665EBE250F32eeEAA1d410a0798C6?smartContractAddress=0xe08af0b4F8c860D3523E176724Bb2bCC54f3bC04/",
      },
      {
        name: "Certik Audit Report",
        path: "https://skynet.certik.com/projects/hypergpt",
      },
      {
        name: "Coinmarketcap",
        path: "https://coinmarketcap.com/currencies/hypergpt/ ",
      },
      {
        name: "CoinGecko",
        path: "https://www.coingecko.com/en/coins/hypergpt ",
      },
    ],
  },
  {
    name: "Community",
    path: "/",
    isSubmenu: true,
    submenuItems: [
      {
        name: "Telegram Chat",
        path: "https://t.me/HyperGPTai ",
      },
      {
        name: "Telegram News",
        path: "https://t.me/hypergpt_news ",
      },
      {
        name: "Discord",
        path: "https://discord.com/invite/hypergpt ",
      },
      {
        name: "Twitter",
        path: "https://x.com/hypergpt ",
      },

      {
        name: "LinkedIn",
        path: "https://www.linkedin.com/company/hypergpt/ ",
      },

      {
        name: "Snapshot",
        path: "https://snapshot.org/#/hypergptdao.eth ",
      },

      {
        name: "Guild",
        path: "https://guild.xyz/hypergpt ",
      },
      {
        name: "Zealy",
        path: "https://zealy.io/c/hypergpt ",
      },
    ],
  },
  {
    name: "Hypers",
    path: "/",
    isSubmenu: true,
    submenuItems: [
      {
        name: "HyperDAO ",
        path: "/dao",
      },
      {
        name: "Ambassador Program",
        path: "/ambassador-program",
      },
      {
        name: "Partners",
        path: "/partners"
      }
    ],
  },
  {
    name: "Learn",
    path: "/",
    isSubmenu: true,
    submenuItems: [
      {
        name: "Docs",
        path: "https://docs.hypergpt.ai/hypergpt/  ",
      },
      {
        name: "News",
        path: "https://medium.com/@hypergptai ",
      },
      {
        name: "Videos",
        path: "https://www.youtube.com/@HyperGPT",
      },
    ],
  },

  // {
  //   name: "Contact",
  //   path: "/",
  //   isSubmenu: false,
  // },

  // {
  //   name: "Pricing",
  //   path: "/pricing",
  //   isSubmenu: false,
  // },
  // {
  //   name: "Verification",
  //   path: "/verification",
  //   isSubmenu: false
  // }
];
