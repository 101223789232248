import React from "react";
import styled from "styled-components";
import { RiCloseFill } from "react-icons/ri";

const Modal = ({ show, onClose, children }) => {
  if (!show) return null;

  return (
    <Overlay >
      <ModalContainer>
        <CloseButton onClick={onClose}>
          <RiCloseFill color="white"  />
        </CloseButton>
        <Content>{children}</Content>
      </ModalContainer>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: #232325;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const Content = styled.div`
  margin-top: 10px;
`;

export default Modal;
