// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Marquee_Marquee__img__qo-\\+R {
  width: 200px;
  margin: 0 2rem;
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.Marquee_Marquee__img__qo-\\+R:hover {
  filter: grayscale(0%);
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/Marquee.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;EACA,uBAAA;EAEA,gCAAA;EACA,eAAA;AAAF;AACE;EACE,qBAAA;AACJ","sourcesContent":[".Marquee__img {\n  width: 200px;\n  margin: 0 2rem;\n  filter: grayscale(100%);\n\n  transition: all 0.3s ease-in-out;\n  cursor: pointer;\n  &:hover {\n    filter: grayscale(0%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Marquee__img": `Marquee_Marquee__img__qo-+R`
};
export default ___CSS_LOADER_EXPORT___;
