import { instance as api } from "./Api";

export const getPackages = async () => {
  try {
    const response = await api.get(`/app/api_packages`);
    return response.data;
  } catch (error) {
    console.error("An error occurred while fetching data: ", error);
    throw error;
  }
};

export const orderHistory = async () => {
  try {
    const response = await api.post(`/app/api_order_history`);
    return response.data;
  } catch (error) {
    console.error("An error occurred while fetching data: ", error);
    throw error;
  }
};
